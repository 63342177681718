import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { InsightsPathsEnum } from '../../../../public/static/insights-paths.enum'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { IInsightProps } from '../../../interfaces/InsightsInterface'
import { RemoteConfigKeys } from '../../../services/firebase/RemoteConfig'
import WordpressPersonoService from '../../../services/WordpressPersonoService'
import { media } from '../../../styles/breakpoints'
import { formatCreationDate, generatePostLink } from '../../../utils/post'
import { CarouselSlider } from '../../molecules/CarouselSlider'
import { MediumFlexiblePostCard } from '../../molecules/PostCard'
import { PromotionalBanner } from '../../molecules/PromotionalBanner'
import { Results } from '../../molecules/Results'
import { ResultsEmpty } from '../../molecules/ResultsEmpty'
import BlogTags from '../../organisms/BlogTags'
import { CategoryHeader } from '../../organisms/CategoryHeader'
import FixedLeeds from '../../organisms/FixedLeads'
import { PaginationHandler } from '../../organisms/Pagination'
import PopularPosts from '../../organisms/PopularPosts'
import LeadStore from '../../stores/LeadStore/LeadStore'
import PaginationStore from '../../stores/PaginationStore/PaginationStore'
import TagsStore from '../../stores/TagsStore/TagsStore'
import { Base } from '../../templates/Base'
import { insightsMeta, insightsMetaEn } from './meta'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1220px;
  margin-top: 42px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;

  ${media.tabMUp} {
    margin-top: 80px;
    width: 100%;
  }
  
  ${media.deskGUp} {
    margin: 96px auto 0;
  }

  ${media.deskGG} {
    padding: 0;
  }
`

const GridContainer = styled.div`
  display: block;
  padding: 0 20px 40px;

  ${media.deskGUp} {
    padding: 0 0 30px;
  }
`

const CardGrid = styled.div<{ isUniqueCard: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-gap: min(30px, 2vw);
  grid-template-columns: repeat(1, 100%);
  
  @media only screen and (min-width: 800px) {
    grid-template-columns: repeat(3, min(300px, 30vw));
  }

  @media only screen and (min-width: 990px) {
    grid-template-columns: repeat(3, min(390px, 30vw));    
  }

  @media only screen and (min-width: 1260px) {
    min-width: 1050px;
    max-width: 1230px;
    grid-template-columns: repeat(3, 390px);
  }
`

const Section = styled.div`
  width: 100%;
  display: flex;
  background-color: #FFF;
`

const LastPostsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  
  @media only screen and (min-width: 710px) {
    justify-content: flex-start;
    align-items: center;
    min-width: 710px;
    margin: 0 auto;
  }
  
  @media only screen and (min-width: 970px) {
    justify-content: flex-start;
    align-items: center;
    min-width: 710px;
  }

  @media only screen and (min-width: 1260px) {
    min-width: 1050px;
    max-width: 1230px;
    justify-content: flex-start;
    align-items: center;
  }

  & > h3 {
    font-size: 20px;
    font-weight: 700;
    color: #212121;
    
    @media only screen and (min-width: 710px) {
      margin-left: 30px;
    }

    @media only screen and (min-width: 1260px) {
      margin-left: 0;
    }

    ${media.tabMUp} {
      font-size: 24px;
    }  
  }
`

export const Insights = observer((props: IInsightProps) => {
  const { t: translate } = useTranslation(LocaleNamespaces.INSIGHTS)
  const tagsStore = new TagsStore(new WordpressPersonoService(props.language))
  const leadStore = new LeadStore()
  const paginationStore = new PaginationStore()

  const currentPage = props.page != null ? parseInt(props.page) : 1
  const baseTitle = translate('page-title')
  const title = currentPage != 1
    ? `${baseTitle} ${translate('page')} ${currentPage}`
    : baseTitle

  const allowedPathNames: { [key: string]: InsightsPathsEnum } = {
    'tags': InsightsPathsEnum.TAGS,
    'category': InsightsPathsEnum.CATEGORY,
    'search': InsightsPathsEnum.SEARCH,
    '': InsightsPathsEnum.DEFAULT
  }

  const path = allowedPathNames[props.urlPathName]

  useEffect(() => {
    if (currentPage > 1 && path != 'tags') {
      const anchor = window.document.getElementById('anchor')
      if (anchor) {
        window.scrollTo({ left: 0, top: anchor.clientHeight, behavior: 'smooth' })
      }
    }
  }, [])

  return (
    <Base title={title} meta={props.language == 'en' ? insightsMetaEn(currentPage.toString(), props.slug) : insightsMeta(currentPage.toString(), props.slug)} language={props.language}>
      {props.language == 'en' && props.shouldShowPromotionalBanner && <PromotionalBanner remoteConfigKey={RemoteConfigKeys.INTERNATIONAL_BANNER} />}

      {props.carousel && <CarouselSlider children={props.carousel} />}

      {path == InsightsPathsEnum.SEARCH ?
        (!props.gridPosts
          ? <ResultsEmpty
            title={translate('results-empty')}
            info={translate('results-empty-values', { returnObjects: true })}
            term={props.term as string}
            tagsStore={tagsStore}
            tagsTitle={translate('highlight-tags')}
            language={props.language}
          />
          : <Results title={translate('youve-searched-for')} subtitle={translate('main-results')} term={props.term as string} />)
        : <></>
      }

      {path == InsightsPathsEnum.CATEGORY && props.categoryAtributes != null ?
        <CategoryHeader
          {...props.categoryAtributes}
        />
        : <> </>
      }

      {!props.categoryAtributes && path != InsightsPathsEnum.SEARCH &&
        <BlogTags
          title={translate('you-might-also-like')}
          secondTitle={translate('you-are-in')}
          language={props.language}
          tagsStore={tagsStore}
        />
      }

      <FixedLeeds
        title={translate('modal-text')}
        errorText={translate('modal-invalid-email')}
        successText={translate('modal-valid-email')}
        button={translate('modal-button')}
        placeholder={translate('modal-placeholder')}
        store={leadStore}
        enVersion={props.language == 'en'}
      />

      <Section>
        <Container>
          {(currentPage == 1 && props.language != 'en' && path == '')
            ? <LastPostsHeader><h3>{translate('last-posts')}</h3></LastPostsHeader>
            : <></>
          }

          {props.gridPosts != null &&
            <GridContainer>
              <CardGrid isUniqueCard={props.gridPosts?.length == 1}>
                {props.gridPosts?.map((data, index) => {
                  return (
                    <MediumFlexiblePostCard
                      image={data.image}
                      videoThumb={data.videoThumb}
                      image_webp={data.image_webp}
                      title={data.title}
                      href={generatePostLink(data.categorySlug, data.slug)}
                      creationDate={formatCreationDate(data.creationDate, props.language)}
                      excerpt={data.excerpt}
                      key={`${data.id}-${index}`}
                    />
                  )
                })}
              </CardGrid>
            </GridContainer>
          }

          {props.language != 'en' && props.shouldShowPromotionalBanner && <PromotionalBanner remoteConfigKey={RemoteConfigKeys.BANNER} />}

          {props.numberOfPages != null && props.gridPosts?.length > 0 &&
            <PaginationHandler
              slug={props.term ?? props.slug}
              currentPage={currentPage}
              path={path}
              numberOfPages={props.numberOfPages}
              store={paginationStore}
            />
          }
        </Container>
      </Section>

      {path != InsightsPathsEnum.SEARCH
        && <PopularPosts language={props.language} title="Os mais acessados" />
      }
    </Base>
  )
})