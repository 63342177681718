import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ITagsItems } from '../../../services/interfaces/WordpressServiceDtos'
import WordpressPersonoService from '../../../services/WordpressPersonoService'
import { media } from '../../../styles/breakpoints'
import { BulletTag, SelectedBulletTag } from '../../atoms/BulletTag'
import TagsStore from '../../stores/TagsStore/TagsStore'

const Wrapper = styled.div<{enVersion: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${({enVersion}) => enVersion ? '-10px' : '30px'} 0 -20px;

  @media only screen and (min-width: 770px) {
    margin: ${({enVersion}) => enVersion ? '-10px' : '40px'} 0 -60px;
  }
`

const Header = styled.h3`
  color: #212121;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 710px) {
    text-align: left;
    width: max-content;
    padding-left: 0;
    font-size: 24px;
    margin: 16px 0;
  }
`

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  @media only screen and (min-width: 710px) {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    min-width: 710px;
    margin: 0 auto;
    padding: 0 30px;
  }

  @media only screen and (min-width: 1260px) {
    padding: 0;
    min-width: 1050px;
    max-width: 1230px;
    justify-content: flex-start;
    align-items: center;
  }
`

const AnimatedSkeletonLoading = styled.div`
  min-width: 130px;
  height: 40px;
  border-radius: 30px;
  border: 2px solid rgba(217, 217, 217, 1);
  background-color: rgba(217, 217, 217, 1);
  margin-right: 10px;
  
  animation: skeleton-loading 800ms linear infinite;
  background-image: linear-gradient(90deg, rgba(217, 217, 217, 1) 0px,rgba(255, 255, 255, 0.3) 20px, rgba(217, 217, 217, 1) 40px);
  background-size: 250px;
  background-position: 0;

  @keyframes skeleton-loading {
    0% {
      background-position: -50px;
    }
    100% {
      background-position: 130px;
    }
  }
`

const TagsRow = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  min-width: 1050px;
  max-width: 1230px;

  ::-webkit-scrollbar {
    display: none;
  }
  
  @media only screen and (min-width: 710px) {
    display: flex;    
    flex-wrap: nowrap;
  }

  @media only screen and (min-width: 768px) {
    margin: 0 30px;
  }

  @media only screen and (min-width: 1260px) {
    margin: 0 auto;
    display: flex;
    width: 100%;
    min-width: 1050px;
    max-width: 1230px;
    flex-wrap: wrap;
    overflow-x: unset;
  }
`

const TagsRowShadow = styled.div`
  position: absolute;
  right: 0;
  min-width: 1px;
  min-height: 50px;
  box-shadow: -10px 0px 35px 30px #FFFFFFAA;
`

const Spacer = styled.div<{ width: string }>`
  display: flex;
  background-color: transparent;
  min-width: ${props => props.width};
  ${media.tabMUp}{
    display: none;
  }
`

export interface IBlogTags {
  title: string,
  language: string,
  secondTitle: string,
  tagsStore: TagsStore
}

const BlogTags = observer((props: IBlogTags) => {
  const { title, language, secondTitle, tagsStore } = props
  const [renderedSlug, setRenderedSlug] = useState('')

  const getTags = async () => {
    await tagsStore.getTags(language)
  }

  const renderAnimatedSkeleton = () => {
    const skeletonList = []
    for (let i = 0; i <= 5; i++) {
      skeletonList.push(<AnimatedSkeletonLoading key={`Skeleton-${i}`} />)
    }
    return skeletonList
  }

  const splitSlugName = (value: string) => {
    const splitted = value.split('/insights/tags/')
    let slug = ''
    if (splitted.length <= 1) {
      slug = splitted[0]
    } else {
      if (splitted[1].includes('/')) {
        slug = splitted[1].split('/')[0]
      } else {
        slug = splitted[1]
      }
    }

    if (slug.includes('/')) {
      slug = ''
    }

    setRenderedSlug(slug.replaceAll('-', ' '))
  }

  useEffect(() => {
    splitSlugName(window.location.pathname)
    getTags()
  }, [])

  return (
    <Wrapper data-testid="blog-tags-wrapper" enVersion={language == 'en'}>
      <HeaderWrapper data-testid="blog-tags-header-wrapper">
        <Header data-testid="blog-tags-header" >
          {renderedSlug != '' ? secondTitle : title}
        </Header>

        {renderedSlug != ''
          ? <SelectedBulletTag text={renderedSlug} />
          : <></>
        }
      </HeaderWrapper>
      {renderedSlug != ''
        ? <> </>
        : <TagsRow>
          <TagsRowShadow />
          <Spacer width="20px" />
          {tagsStore.tagsList.length > 0
            ? tagsStore.tagsList.map((tagGroup, index) => (
              <BulletTag key={tagGroup.name + index} slug={tagGroup.slug} text={tagGroup.name} />
            ))
            : <>
              {renderAnimatedSkeleton()}
            </>
          }
          <Spacer width="40px" />
        </TagsRow>
      }
    </Wrapper>
  )
})

export default BlogTags