import styled from 'styled-components'
import { InsightsPathsEnum } from '../../../../public/static/insights-paths.enum'
import { generateNextPageLink } from '../../../utils/post'

const Item = styled.a<{ isInactive: boolean }>`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border: 1px solid #CCC;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  background: ${props => props.isInactive ? '#CCCCCC' : '#FFF'};
  margin: 0 4px;
  padding: 0;
  cursor: pointer;

  &:hover {
    background: ${props => props.isInactive ? '#CCC' : 'linear-gradient(0deg, rgba(0, 112, 247, 0.05), rgba(0, 112, 247, 0.05))'};
  }
`

const DivItem = styled.div<{ isInactive: boolean }>`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border: 1px solid ${props => props.isInactive ? '#CCCCCC ' : '#00A8E0'};
  background: ${props => props.isInactive ? '#CCCCCC' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #00A8E0'};
  border-radius: 50px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin: 0 4px;
  padding: 0;
`
const ArrowDiv = styled.div<{ isLeft: boolean, isInactive: boolean }>`
  transform: ${props => props.isLeft ? 'rotate(180deg)' : 'none'};
  cursor: ${props => props.isInactive ? '' : 'pointer'};
  background-image: url('/images/chevron-arrow.webp');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: ${props => props.isLeft ? '5px 6px' : '6px 4px'};
  min-width: 32px;
  width: 32px;
  height: 32px;
`

interface PaginationItemProps {
  page: number,
  currentPage?: string
  slug?: string | string[],
  path?: InsightsPathsEnum
  isLeft?: boolean,
  maximumPages?: number
}

export const PaginationItemArrowPrev = (props: PaginationItemProps) => {
  const link = generateNextPageLink(parseInt(props.currentPage) - 1, props.slug, props.path)
  const isInactive = props.page == 1

  return (
    <>
      {isInactive
        ? <DivItem isInactive={true} data-testid="pagination-prev-inactive">
          <ArrowDiv isLeft={props.isLeft} isInactive={true} />
        </DivItem>
        : <Item href={link} isInactive={false} data-testid="pagination-prev">
          <ArrowDiv isLeft={props.isLeft} isInactive={false}/>
        </Item>
      }
    </>
  )
}

export const PaginationItemArrowNext = (props: PaginationItemProps) => {
  const link = generateNextPageLink(parseInt(props.currentPage) + 1, props.slug, props.path)
  const isInactive = props.page == props.maximumPages

  return (
    <>
      {
        isInactive
          ? <DivItem isInactive={true} data-testid="pagination-next-inactive">
            <ArrowDiv isLeft={props.isLeft} isInactive={true} />
          </DivItem>
          : <Item href={link} isInactive={false} data-testid="pagination-next">
            <ArrowDiv isLeft={props.isLeft} isInactive={false} />
          </Item>
      }
    </>
  )
}
