import styled from 'styled-components'

export const PromotionalLinkStyle = styled.a`
  margin: 60px 20px;
  padding: 0;
  width: 100%;
  max-height: 300px;

  @media only screen and (min-width: 710px) {
    max-height: 350px;
  }
  
  @media only screen and (min-width: 1250px) {
    width: 100%;
    max-width: 1230px;
  }
`

export const PromotionalImageStyle = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  object-fit: scale-down;

  @media only screen and (min-width: 710px) {
    min-height: 100%;
  }
  
  @media only screen and (min-width: 1260px) {
    object-fit: contain;
    max-width: 1260px;
  }
`