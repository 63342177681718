import { PromotionalImageStyle, PromotionalLinkStyle } from './styles'

interface IPromotionalImage {
  bannerLink: string,
  bannerSrc: string,
  altText: string,
}

export const PromotionalBannerImage = (props: IPromotionalImage) => {
  const { bannerLink, bannerSrc, altText } = props

  return (
    <PromotionalLinkStyle href={bannerLink} target="_blank" data-testid="promotional-banner-link">
      <PromotionalImageStyle
        data-testid="promotional-banner-image"
        src={bannerSrc}
        loading="lazy"
        alt={altText}
      />
    </PromotionalLinkStyle>
  )
}