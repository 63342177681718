import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { BulletTag } from '../../atoms/BulletTag'
import TagsStore from '../../stores/TagsStore/TagsStore'

const Column = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-top: 58px;
  margin-bottom: 78px;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  ${media.tabMUp} {
    margin-top: 194px;
    margin-bottom: 277px;
  }

  & > h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    margin: 0;
    padding: 0;
    color: #757575;
    text-align: center;

    ${media.tabMUp} {
      font-size: 52px;
    }
  }
`

const Row = styled.section`
  width: 100%;
  padding: 0 20px;
  margin: 30px 0 0 0;

  & > h2 {
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    margin: 0;
    color: #757575;
    text-align: center;

    & > span {
      font-weight: 700;
    }

    ${media.tabMUp} {
      font-size: 24px;
    }
  }

  & > h3 {
    font-size: 18px;
    color: #757575;
    line-height: 120%;
    font-weight: 700;
    text-align: center;

    ${media.tabMUp} {
      font-size: 24px;
    }
  }
`

const TagsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 10px 10px;
  }
`
interface IResultsEmpty {
  title: string,
  info: string[],
  term: string,
  tagsStore: TagsStore,
  tagsTitle: string,
  language: string
}

export const ResultsEmpty = observer((props: IResultsEmpty) => {
  const { title, info, term, tagsStore, tagsTitle, language } = props

  const getTags = async () => {
    await tagsStore.getTags(language)
  }

  const renderTags = () => {
    const tagList: React.ReactElement[] = []

    tagsStore.tagsList.map((tag, index) => {
      tagList.push(<BulletTag key={tag.name + index} text={tag.name} slug={tag.slug} />)
    })

    return <TagsRow> {tagList} </TagsRow>
  }

  useEffect(() => {
    getTags()
  }, [])

  return (
    <Column data-testid="main-column">
      <h1 data-testid="title">{title}</h1>

      <Row data-testid="infos-row">
        <h2 data-testid="info-with-term">
          {info[0]} 
          <span data-testid="term-span">"{term}"</span> 
          {info[1]}
        </h2>
        <h2 data-testid="sub-info">
          {info[2]}
        </h2>
      </Row>

      <Row data-testid="middle-row">
        <h2>{info[3]}</h2>
      </Row>

      <Row data-testid="tags-row">
        <h3 data-testid="tags-title">{tagsTitle}</h3>
        {renderTags()}
      </Row>
    </Column>
  )
})