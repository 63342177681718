import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getRemoteConfigValue, RemoteConfigKeys } from '../../../services/firebase/RemoteConfig'
import { IPopularPostsResponse } from '../../../services/interfaces/FirebaseServiceDtos'
import { IPost } from '../../../services/interfaces/WordpressServiceDtos'
import WordpressPersonoService from '../../../services/WordpressPersonoService'
import { formatCreationDate, generatePostLink } from '../../../utils/post'

const Wrapper = styled.div`
  min-height: 500px;
  max-height: 500px;
  width: 100%;
  max-width: 100%;
  background-color: #D7EEF4;
  padding-top: 24px;

  @media only screen and (min-width: 800px) {
    min-height: 460px;
    max-height: 100%;
  }

  @media only screen and (min-width: 1260px) {
    min-height: 340px;
    max-height: 100%;
    padding-top: 36px;
  }
`

const Title = styled.h2`
  font-family: 'Lato' sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin: 0 auto 24px;
  color: #212121;
  text-align: center;

  @media only screen and (min-width: 800px) {
    margin: 0 auto 20px;
    font-size: 24px;
    width: 100%;
  }

  @media only screen and (min-width: 1260px) {
    text-align: left;
    padding-left: 30px;
  }
`

const DesktopTitleWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
`

const PostWrapper = styled.a`
  text-decoration: none;
  max-width: 300px;

  @media only screen and (min-width: 800px) {
    width: min(300px, 30vw);
  }

  @media only screen and (min-width: 1260px) {
    display: flex;
    min-width: 400px;
    max-width: 400px;
    flex-direction: row;
    cursor: pointer;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: unset;
    transition-property: box-shadow, transform, opacity, filter;
    transition: 300ms ease-in-out;
    will-change: box-shadow, transform, opacity, filter;
    
    &:hover {
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3), 0px 6px 20px #D2D2D7;

      & > * {  
        & > img {
          width: 192px;
          transform: scale3d(1.04, 1.04, 1);
          filter: brightness(0.8);
          overflow: hidden;
        }

      }
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  max-height: 182px;
  margin: 0 auto;

  @media only screen and (min-width: 800px) {
    width: min(350px, 30vw);
    margin: 0;
  }
  @media only screen and (min-width: 1030px) {
    width: min(350px, 30vw);
  }

  @media only screen and (min-width: 1260px) {
    min-width: 195px;
    max-width: 195px;
    margin: 0;
  }

  & > img {
    object-fit: cover;
    width: 100%;
    min-height: 185px;
    max-height: 185px;
    border-radius: 15px 15px 0 0;
    
    @media only screen and (min-width: 800px) {
      max-width: 100%;
      min-height: 185px;
      max-height: 185px;
    }

    @media only screen and (min-width: 1260px) {
      will-change: width filter;
      transition: all ease-in-out 300ms;
      max-width: 195px;
      min-height: 200px;
      max-height: 200px;
      border-radius: 15px 0 0 15px;
      padding: 0;
      margin: 0;
    }
  }
`

const MobileWrapper = styled.div<{ width: string }>`
  display: flex;
  visibility: visible;
  height: 470px;
  max-height: 440px;
  border-radius: 15px;
  flex-direction: column;

  & > .popularCarousel {
    height: 380px;
    max-height: 470px;
    max-width: ${props => `${props.width}px` ?? '300px'};

    .swiper-pagination {
      visibility: visible;
      position: absolute;
      bottom: 0px;
    }

    .swiper-pagination-bullet {
      background: rgba(0, 0, 0, 0.5);;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
  max-height: 160px;
  background-color: white;
  border-radius: 0 0 15px 15px;

  @media only screen and (min-width: 800px) {
    width: min(350px, 30vw);
  }

  @media only screen and (min-width: 1260px) {
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    max-height: 200px;
  }

  & > p {
    font-family: 'Lato' sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #212121;
    margin: 20px 15px;

    @media only screen and (min-width: 1260px) {
      will-change: margin;
      transition: all ease-in-out 200ms;
    }
  }

  & > span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #757575;
    margin: 0 15px 16px;

    @media only screen and (min-width: 1260px) {
      font-size: 12px;
      margin: 0 15px 15px;
      will-change: margin;
      transition: all ease-in-out 200ms;
    }
  }
`

const PostCard = styled.div`
  width: calc(100% - 60px);
  border-radius: 15px;
  text-decoration: none;
  max-height: 340px;
  background-color: white;
  margin: 0 auto;
  padding: 0;

  @media only screen and (min-width: 800px) {
    min-width: max-content;
    margin: 0 10px;
  }
  
  @media only screen and (min-width: 1100px) {
    min-width: max-content;
    margin: 0 10px;
  }

  @media only screen and (min-width: 1260px) {
    min-width: 400px;
    max-width: 400px;
    min-height: 200px;
    max-height: 200px;
    margin: 0 0 25px 0;
  }
`

const DesktopWrapper = styled.div`
  width: 100%;
  height: max-content;
  overflow: visible;
`

const PostsRow = styled.div`
  max-width: max-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;
  transition: all ease-in-out 200ms;
  
  @media only screen and (min-width: 800px) {
    width: 100%;
    max-width: max-content;
  }
  
  @media only screen and (min-width: 1260px) {
    padding: 0;
    gap: min(30px, 1.5vw);
    max-width: max-content;
  }
  
`

const PopularPosts = ({ language = 'pt', title = 'Os mais acessados' }) => {
  const [posts, setPosts] = useState<IPost[]>()
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const wordpressService = new WordpressPersonoService(language)

  const getPosts = async () => {
    const postGroup: IPost[] = []
    const remoteConfigResponse: IPopularPostsResponse = await getRemoteConfigValue<IPopularPostsResponse>(RemoteConfigKeys.POSTS)
    await wordpressService.getPostBySlugAndCategory(remoteConfigResponse.slug, null).then(
      response => {
        response.map(post => {
          postGroup.push(post.data)
        })
      }
    )
    setPosts(postGroup)
  }

  const checkResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    getPosts()
    window.addEventListener('resize', checkResize)
    checkResize()
  }, [])

  return (
    <>
      {windowWidth && posts && posts.length === 3 &&
        <Wrapper>
          {windowWidth < 800 &&
            <MobileWrapper width={windowWidth.toString()}>
              <Title>{title}</Title>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: false,
                  enabled: true
                }}
                navigation={false}
                modules={[Pagination]}
                className="popularCarousel"
              >
                {posts.map(
                  (post, index) => {
                    return (
                      <SwiperSlide key={post.title + index} className="slide">
                        <PostCard>
                          <PostWrapper href={generatePostLink(post.categorySlug, post.slug)}>
                            <ImageContainer>
                              <img src={post.image_webp ?? post.image} alt={post.title} loading="lazy" />
                            </ImageContainer>
                            <TextContainer>
                              <p>{post.title}</p>
                              <span>{formatCreationDate(post.creationDate, language)}</span>
                            </TextContainer>
                          </PostWrapper>
                        </PostCard>
                      </SwiperSlide>
                    )
                  }
                )}
              </Swiper>
            </MobileWrapper>
          }

          {windowWidth >= 800 &&
            <>
              <DesktopTitleWrapper>
                <Title>{title}</Title>
              </DesktopTitleWrapper>
              <DesktopWrapper>
                <PostsRow>
                  {posts.map((post, index) => {
                    return (
                      <PostCard key={post.title + index}>
                        <PostWrapper href={generatePostLink(post.categorySlug, post.slug)}>
                          <ImageContainer>
                            <img src={post.image_webp ?? post.image} alt={post.title} loading="lazy" />
                          </ImageContainer>
                          <TextContainer>
                            <p>{post.title}</p>
                            <span>{formatCreationDate(post.creationDate, language)}</span>
                          </TextContainer>
                        </PostWrapper>
                      </PostCard>
                    )
                  })}
                </PostsRow>
              </DesktopWrapper>
            </>
          }
        </Wrapper>
      }
    </>
  )
}

export default PopularPosts