import { captureException } from '@sentry/nextjs'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getRemoteConfigValue, RemoteConfigKeys } from '../../../services/firebase/RemoteConfig'
import { IPromotionalBanner } from '../../../services/interfaces/FirebaseServiceDtos'
import { media } from '../../../styles/breakpoints'
import { PromotionalBannerImage } from '../../atoms/PromotionalBannerImage'

const PromotionalBannerContainer = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  min-width: ${({ visible }) => visible ? '100%' : '0px'};
  background-color: transparent;
  justify-content: center;
  max-width: 768px;

  ${media.tabMUp} {
    padding: 0;
    max-width: 1260px;
  }
`

interface IPromotionalBannerProps {
  remoteConfigKey: RemoteConfigKeys
}

export const PromotionalBanner = ({ remoteConfigKey }: IPromotionalBannerProps) => {
  const [mobileBanner, setMobileBanner] = useState<string>(null)
  const [desktopBanner, setDesktopBanner] = useState<string>(null)
  const [mobileBannerLink, setMobileBannerLink] = useState<string>(null)
  const [desktopBannerLink, setDesktopBannerLink] = useState<string>(null)
  const [altText, setAltText] = useState<string>(null)
  const [isMobile, setIsMobile] = useState(true)

  const getBannerInfos = async () => {
    try {
      const response = await getRemoteConfigValue<IPromotionalBanner>(remoteConfigKey)
      
      setDesktopBanner(response.desktop_url)
      setMobileBanner(response.mobile_url)
      setDesktopBannerLink(response.desktop_link)
      setMobileBannerLink(response.mobile_link)
      setAltText(response.alt_text)

      window.addEventListener('resize', handleResize)
      handleResize()
    } catch (e) {
      captureException(e)
    }
  }

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 800)
  }

  useEffect(() => {
    getBannerInfos()
  }, [])

  return (
    <PromotionalBannerContainer data-testid="promotional-banner-container" visible={altText != null && altText != ''}>
      <PromotionalBannerImage 
        bannerLink={isMobile ? mobileBannerLink : desktopBannerLink}
        bannerSrc={isMobile ? mobileBanner : desktopBanner}
        altText={altText}
      />
    </PromotionalBannerContainer>
  )
}