import { IPostMeta } from '../../../services/interfaces/WordpressServiceDtos'

export const insightsMeta = (page?: string, tag?: string) => {
  const ogdescription = { property: 'og:description', content: `Todos os conteúdos para você dormir melhor: como dormir mais rápido, por que você acorda de madrugada, porque sonhamos e mais!`}
  const description = { name: 'description', content: 'Todos os conteúdos para você dormir melhor: como dormir mais rápido, por que você acorda de madrugada, porque sonhamos e mais!' }
  
  if(tag) {
    const tagName = tag.charAt(0).toUpperCase() + tag.slice(1)
    description.content += ` - ${tagName}`
    ogdescription.content += ` - ${tagName}`
  }

  if(page && page != '1') {
    description.content += ` - Pagina ${page}`
    ogdescription.content += ` - Pagina ${page}`
  }

  const meta: IPostMeta[] = [
    { property: 'og:title', content: 'Blog Persono - Qualidade do sono, insônia, produtividade e mais' },
    { property: 'twitter:title', content: 'Blog Persono - Qualidade do sono, insônia, produtividade e mais' },
    description,
    ogdescription,
    { property: 'og:image', content: 'https://persono.com.br/images/ogp/insights.jpg' },
    { property: 'og:image:secure_url', content: 'https://persono.com.br/images/ogp/insights.jpg' },
    { property: 'og:image:type', content: 'image/jpeg' },
    { property: 'og:image:width', content: '667' },
    { property: 'og:image:height', content: '1000' },
    { property: 'og:image:alt', content: 'Não existe vida saudável sem qualidade do sono elevada. É nisso que o Persono acredita: durma bem, viva melhor.' },
  ]

  return meta
}

export const insightsMetaEn = (page?: string, tag?: string) => {
  const ogdescription = { property: 'og:description', content: `Content for you to sleep better: how to sleep faster, why you wake up in the middle of the night, why we dream and more!`}
  const description = { name: 'description', content: `Content for you to sleep better: how to sleep faster, why you wake up in the middle of the night, why we dream and more!`}
  
  if(tag) {
    const tagName = tag.charAt(0).toUpperCase() + tag.slice(1)
    description.content += ` - ${tagName}`
    ogdescription.content += ` - ${tagName}`
  }

  if(page && page != '1') {
    description.content += ` - Page ${page}`
    ogdescription.content += ` - Page ${page}`
  }

  const meta: IPostMeta[] = [
    { property: 'og:title', content: 'Persono Blog - Sleep quality, insomnia, productivity and more' },
    { property: 'twitter:title', content: 'Persono Blog - Sleep quality, insomnia, productivity and more' },
    ogdescription,
    { property: 'og:image', content: 'https://persono.com.br/images/ogp/insights.jpg' },
    { property: 'og:image:secure_url', content: 'https://persono.com.br/images/ogp/insights.jpg' },
    { property: 'og:image:type', content: 'image/jpeg' },
    { property: 'og:image:width', content: '667' },
    { property: 'og:image:height', content: '1000' },
    { property: 'og:image:alt', content: 'There is no healthy life without high quality sleep. That\'s what Persono believes in: better sleep, better life. ' },
    description,
  ]

  return meta
}