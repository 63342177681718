import { action, observable } from 'mobx'
import { ITagsItems } from '../../../services/interfaces/WordpressServiceDtos'
import WordpressPersonoService from '../../../services/WordpressPersonoService'

class TagsStore {
  private wordpressPersonoService: WordpressPersonoService

  constructor(wordpressPersonoService: WordpressPersonoService) {
    this.wordpressPersonoService = wordpressPersonoService
  }

  @observable
  tagsList: ITagsItems[] = []

  @action
  getTags = async (language: string) => {
    this.tagsList = []
    const tags = await this.wordpressPersonoService.getHighlightTags(language)
    tags.map(tag => this.tagsList.push(...tag.terms))
  }
}

export default TagsStore