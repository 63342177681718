import { action, observable } from 'mobx'

class PaginationStore {
  @observable page = 1
  @observable maximumPages = 30
  @observable pagesToRender = []

  @action
  nextPage = () => {
    if (this.page <= this.maximumPages) {
      this.page += 1
    }
  }

  @action
  previousPage = () => {
    if (this.page >= 1) {
      this.page -= 1
    }
  }

  @action
  setPage = (pageNumber: number) => {
    this.page = pageNumber
  }

  @action
  setMaximumPages = (maxNumberOfPages: number) => {
    this.maximumPages = maxNumberOfPages
  }

  @action
  setPagesToRender = () => {
    this.pagesToRender = []
    const page = this.page.toString()
    const maxPage = this.maximumPages.toString()
    const pageValue = parseInt(page)
    const maxValue = parseInt(maxPage)

    if (pageValue < 3 && maxValue >= 5) { // case 1
      for (let x = 1; x <= 5; x++) {
        this.pagesToRender.push(x)
      }
      return
    }

    if (maxValue <= 5) { // case 2
      for (let x = 1; x <= maxValue; x++) {
        this.pagesToRender.push(x)
      }
      return
    }

    if (pageValue >= 3 && maxValue > 5) { // case 3 ~
      if (pageValue == maxValue) {
        for (let x = -4; x <= 0; x++) { // case 3.2
          this.pagesToRender.push(pageValue + x)
        }
      } else if (pageValue == maxValue - 1) { // case 3.3
        for (let x = -3; x <= 1; x++) {
          this.pagesToRender.push(pageValue + x)
        }
      } else { // case 3.1
        for (let x = -2; x <= 0; x++) {
          this.pagesToRender.push(pageValue + x)
        }
        for (let x = 1; x <= 2; x++) {
          this.pagesToRender.push(pageValue + x)
        }
      }
      return
    }
  }
}

export default PaginationStore