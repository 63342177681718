import { observer } from 'mobx-react'
import { useEffect } from 'react'
import styled from 'styled-components'
import { InsightsPathsEnum } from '../../../../public/static/insights-paths.enum'
import { PaginationItem } from '../../atoms/PaginationItem'
import { PaginationItemArrowNext, PaginationItemArrowPrev } from '../../atoms/PaginationItem/PaginationItemArrow'
import PaginationStore from '../../stores/PaginationStore/PaginationStore'

export interface IPaginationHandler {
  currentPage: number,
  numberOfPages: number,
  slug: string | string[],
  path: InsightsPathsEnum,
  store: PaginationStore
}

const PaginationContainer = styled.div`
  width: 100%;
  min-width: 300px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin: 30px auto 37px;
`

export const PaginationHandler = observer((props: IPaginationHandler) => {
  const { currentPage, numberOfPages, slug, path, store } = props

  useEffect(() => {
    store.setPage(currentPage)
    store.setMaximumPages(numberOfPages)
    store.setPagesToRender()
  }, [])

  return (
    <>
      <PaginationContainer data-testid="pagination-container-desktop">
        <PaginationItemArrowPrev
          slug={slug}
          page={store.page}
          currentPage={currentPage.toString()}
          isLeft={true}
          path={path}
        />

        {store.pagesToRender.map(index => {
          return (
            <PaginationItem
              key={index}
              isActive={store.page == index}
              value={index}
              slug={slug}
              urlPathName={path}
            />
          )
        })
        }

        <PaginationItemArrowNext
          slug={slug}
          page={store.page}
          currentPage={currentPage.toString()}
          isLeft={false}
          path={path}
          maximumPages={store.maximumPages}
        />

      </PaginationContainer>
    </>
  )
})