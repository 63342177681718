import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1300px;
  margin: 36px auto -30px;
  padding: 0 30px;
  
  ${media.tabMUp} {
    padding: 0 40px;
    margin: 60px auto -80px;
  }
`

const Row = styled.div`
  margin: 20px 0;

  & > h1 {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 700;
    font-family: 'Lato' sans-serif;
    color: #212121;

    ${media.tabMUp} {
      font-size: 32px;
    }
  }

  & > h2 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Lato' sans-serif;
    color: #757575;

    ${media.tabMUp} {
      font-size: 28px;
    }
  }
`

interface IResultsEmpty {
  title: string,
  subtitle: string,
  term: string
}

export const Results = (props: IResultsEmpty) => {
  const { title, subtitle, term } = props

  return (
    <Column data-testid="results-column">
      <Row data-testid="results-row-title-and-term">
        <h1>{title} "{term}"</h1>        
      </Row>

      <Row data-testid="results-row-subtitle">
        <h2>{subtitle}</h2>
      </Row>
    </Column>
  )
}