import styled from 'styled-components'
import { InsightsPathsEnum } from '../../../../public/static/insights-paths.enum'
import { generateNextPageLink } from '../../../utils/post'

const PaginationItemStyle = styled.a<{ isActive: boolean }>`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border: ${props => props.isActive ? '1px solid  #0070f7' : 'none'};
  background-color: ${props => props.isActive ? '#0070f7' : 'none'};
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  font-size: 17px;
  color: ${props => props.isActive ? '#FFF' : '#0070f7'};
  margin: 0 4px;
  cursor: pointer;

  &:hover {
    background: ${props => props.isActive ? '#0070f7' : 'linear-gradient(0deg, rgba(0, 112, 247, 0.05), rgba(0, 112, 247, 0.05))'};
  }
`

interface DesktopPaginationItemInterface {
  isActive: boolean,
  value: number,
  slug: string | string[],
  urlPathName: InsightsPathsEnum
}


export const PaginationItem = (props: DesktopPaginationItemInterface) => {
  return (
    <PaginationItemStyle
      isActive={props.isActive}
      href={props.isActive ? null : generateNextPageLink(props.value, props.slug, props.urlPathName)}
      key={props.value}
    >
      {props.value}
    </PaginationItemStyle>
  )
}